"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _moment = _interopRequireDefault(require("moment"));

var _report = require("@/api/report");

var _cashExpense = require("@/api/cashExpense");

var _elementUi = require("element-ui");

var _objectHash = _interopRequireDefault(require("object-hash"));

var _errorParse = require("@/utils/error-parse");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _pluralize = _interopRequireDefault(require("pluralize"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import LineChart from '@/components/Chart/lineChart'
var _default = {
  name: 'Dashboard',
  components: {
    /* LineChart, */
    Pagination: _Pagination.default
  },

  beforeRouteLeave(to, from, next) {
    if (this.clearOnRouteChange.length) {
      for (const instance of this.clearOnRouteChange) {
        if (instance instanceof AbortController) {
          instance.abort();
        } else {
          clearTimeout(instance);
        }
      }

      this.clearOnRouteChange = [];
    }

    next();
  },

  data() {
    return {
      storeStaffDutyData: [],
      storeStaffDutyLoading: false,
      laborCostData: [],
      laborCostLoading: false,
      fullTimeCostData: [],
      fullTimeCostLoading: false,
      suppliersPO: [],
      suppliersStoreMode: 'real',
      suppliersStores: {
        realHash: null,
        real: [],
        estimateHash: null,
        estimate: []
      },
      // suppliersStore: [],
      totalSupplierPO: 0,
      totalSupplierStore: 0,
      poQuery: {
        fromTime: '',
        toTime: '',
        page: 1,
        limit: 10
      },
      supplierSortBy: {
        'amount': 'descending'
      },
      supplierQuery: {
        fromTime: '',
        toTime: '',
        page: 1,
        limit: 10
      },
      warehouses: [],
      totalWarehouse: 0,
      warehouseSortBy: {
        'amount': 'descending'
      },
      warehouseQuery: {
        fromTime: '',
        toTime: '',
        page: 1,
        limit: 20
      },
      shouldShowStoreCost: false,
      storeCostChartOptions: null,
      staffDate: {
        fromTime: '',
        toTime: ''
      },
      laborDate: {
        fromTime: '',
        toTime: ''
      },
      fullTimeStaffDate: {
        fromTime: '',
        toTime: ''
      },
      cashExpenses: [],
      cashExpenseParam: {
        category: '',
        date_range: null
      },
      cashExpenseCategories: [{
        id: '',
        name: _i18n.default.t('CashExpense.All')
      }, {
        id: 'Stationary',
        name: _i18n.default.t('CashExpense.Stationary')
      }, {
        id: 'Food',
        name: _i18n.default.t('CashExpense.Food')
      }, {
        id: 'Miscellaneous',
        name: _i18n.default.t('CashExpense.Miscellaneous')
      }, {
        id: 'Laundry',
        name: _i18n.default.t('CashExpense.Laundry')
      }, // {id : "InkBox", name : i18n.t('CashExpense.InkBox')},
      {
        id: 'Logistic',
        name: _i18n.default.t('CashExpense.Logistic')
      }, {
        id: 'Cleaning',
        name: _i18n.default.t('CashExpense.Cleaning')
      }, {
        id: 'FoodIngredients',
        name: _i18n.default.t('CashExpense.FoodIngredients')
      }, {
        id: 'Bar',
        name: _i18n.default.t('CashExpense.Bar')
      }],
      cashExpenseLoading: false,
      supplierLoading: true,
      pickerMinDate: '',
      pickerOptions: {
        onPick: ({
          maxDate,
          minDate
        }) => {
          // console.log({ maxDate, minDate })
          if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }

          if (maxDate) {
            this.pickerMinDate = '';
          }
        },
        disabledDate: time => {
          const times = new Date().setDate(new Date().getDate() + 1);

          if (this.pickerMinDate !== '') {
            var pickedDate = new Date(this.pickerMinDate);
            var minDate = new Date(pickedDate.getYear(), pickedDate.getMonth(), 0).getDate() - 1;
            var maxDate = new Date(pickedDate.getYear(), pickedDate.getMonth() + 1, 0).getDate() - 1;
            const min = minDate * 24 * 3600 * 1000;
            const max = maxDate * 24 * 3600 * 1000;
            const minTime = this.pickerMinDate - min;
            let maxTime = this.pickerMinDate + max;

            if (maxTime > times) {
              maxTime = times - 8.64e7;
            }

            return time.getTime() < minTime || time.getTime() > maxTime;
          }

          return time.getTime() > times - 8.64e7;
        }
      },
      clearOnRouteChange: [],
      clearOnStoreStaffDutyChange: [],
      cleanOnSuppliersPOChange: [],
      clearOnSuppliersStoreChange: [],
      deferredShouldShown: window.innerHeight > 700,
      deferred2ShouldShown: false
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    },

    ...(0, _vuex.mapGetters)(['roles', 'user', 'isStandalone']),
    supplierPO: {
      get() {
        return [this.poQuery.fromTime, this.poQuery.toTime];
      },

      set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.poQuery.fromTime = secondDateOfWeek[0];
          this.poQuery.toTime = secondDateOfWeek[1];
        } else {
          this.poQuery.fromTime = '';
          this.poQuery.toTime = '';
        }

        this.beforeChangeSupplierPO();
        this.fetchSuppliersPO();
      }

    },
    supplierWeek: {
      get() {
        return [this.supplierQuery.fromTime, this.supplierQuery.toTime];
      },

      set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.supplierQuery.fromTime = secondDateOfWeek[0];
          this.supplierQuery.toTime = secondDateOfWeek[1];

          if (this.suppliersStoreMode === 'real') {
            this.fetchSuppliersStore();
          } else {
            this.fetchSuppliersStoreByPO();
          }
        } else {
          this.supplierQuery.fromTime = '';
          this.supplierQuery.toTime = '';
        }
      }

    },
    staffWeek: {
      get() {
        return [this.staffDate.fromTime, this.staffDate.toTime];
      },

      set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.staffDate.fromTime = secondDateOfWeek[0];
          this.staffDate.toTime = secondDateOfWeek[1];
        } else {
          this.staffDate.fromTime = '';
          this.staffDate.toTime = '';
        }

        this.fetchStoreStaffDuty();
      }

    },
    laborCostWeek: {
      get() {
        return [this.laborDate.fromTime, this.laborDate.toTime];
      },

      set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.laborDate.fromTime = secondDateOfWeek[0];
          this.laborDate.toTime = secondDateOfWeek[1];
        } else {
          this.laborDate.fromTime = '';
          this.laborDate.toTime = '';
        }

        this.fetchLaborCost();
      }

    },
    FullTimeCostWeek: {
      get() {
        return [this.fullTimeStaffDate.fromTime, this.fullTimeStaffDate.toTime];
      },

      set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.fullTimeStaffDate.fromTime = secondDateOfWeek[0];
          this.fullTimeStaffDate.toTime = secondDateOfWeek[1];
        } else {
          this.fullTimeStaffDate.fromTime = '';
          this.fullTimeStaffDate.toTime = '';
        }

        this.fetchFullTimeCost();
      }

    },
    suppliersStore: {
      get() {
        if ((0, _objectHash.default)(this.supplierWeek) !== this.suppliersStores.realHash) {
          return null;
        }

        return this.suppliersStores.real;
      },

      set(list) {
        this.suppliersStores.real = list;
        this.suppliersStores.realHash = (0, _objectHash.default)(this.supplierWeek);
      }

    },
    suppliersStoreEstimate: {
      get() {
        if ((0, _objectHash.default)(this.supplierWeek) !== this.suppliersStores.estimateHash) {
          return null;
        }

        return this.suppliersStores.estimate;
      },

      set(list) {
        this.suppliersStores.estimate = list;
        this.suppliersStores.estimateHash = (0, _objectHash.default)(this.supplierWeek);
      }

    }
  },
  watch: {
    suppliersStoreMode(newMode) {
      if (newMode === 'real' && this.suppliersStore === null) {
        this.fetchSuppliersStore();
      } else if (newMode === 'estimate' && this.suppliersStoreEstimate === null) {
        this.fetchSuppliersStoreByPO();
      }
    }

  },

  async created() {
    const roles = this.roles.map(role => role.name);
    const arrPromises = [];
    const topPromises = [];

    if (roles.includes('Admin') || roles.includes('Operation Manager') || roles.includes('Floor Manager')) {
      if (!this.staffDate.fromTime) {
        const now = new Date();
        now.setDate(now.getDate() - 8);
        this.staffDate.fromTime = now.toJSON().slice(0, 10);
      }

      if (!this.staffDate.toTime) {
        const toTime = new Date();
        toTime.setDate(toTime.getDate() - 1);
        this.staffDate.toTime = toTime.toJSON().slice(0, 10);
      } // this.shouldShowStoreCost = true


      topPromises.push(this.fetchStoreStaffDuty().then(storeStaffDutyData => {
        // @TODO break down the array into 4 groups and keep fetch the child
        // then daily refetch
        if (this.$workbox && (this.isStandalone || process.env.NODE_ENV !== 'production')) {
          if (window.requestIdleCallback) {
            requestIdleCallback(() => {
              const workers = [];

              for (const index in storeStaffDutyData) {
                const storeStaffDuty = storeStaffDutyData[index];

                if (!Array.isArray(workers[index % 4])) {
                  workers[index % 4] = [];
                }

                workers[index % 4].push({
                  storeId: storeStaffDuty.uuid,
                  fromTime: this.staffDate.fromTime,
                  toTime: this.staffDate.toTime,
                  index
                });
              }

              for (const worker of workers) {
                if (worker[0]) {
                  // save the id, on change route if standalone then must clear this timeout threads, because it's not daily
                  this.clearOnRouteChange.push(setTimeout(() => {
                    let param = worker.shift();
                    let seq = param.index;
                    const controller = new AbortController();

                    while (param && this.storeStaffDutyData[seq].duties.length) {
                      // continue to next worker
                      param = worker.shift();
                      seq = param.index;
                    }

                    delete param.index;
                    this.clearOnRouteChange.push(controller);
                    const promise = (0, _report.getStoreCost)({ ...param
                    }, {
                      signal: controller.signal
                    }).then(response => {
                      if (response && response.data) {
                        this.storeStaffDutyData[seq].duties = response.data[0].duties;
                      }
                    });

                    for (param of worker) {
                      const seq = param.index;
                      delete param.index;
                      promise.then(() => {
                        if (!this.storeStaffDutyData[seq] || !this.storeStaffDutyData[seq].duties.length) {
                          const controller = new AbortController();
                          this.clearOnRouteChange.push(controller);
                          return (0, _report.getStoreCost)({ ...param
                          }, {
                            signal: controller.signal
                          }).then(response => {
                            if (response && response.data) {
                              this.storeStaffDutyData[seq].duties = response.data[0].duties;
                            }
                          });
                        }
                      });
                    }
                  }, 2200));
                }
              }
            });
          } // DO we need daily, How to make it unique, maybe on mounted ? maybe need cancelling
          // @TODO daily might be better in sw
          // setTimeout()

        }
      }));

      if (!this.laborDate.fromTime) {
        const now = new Date();
        now.setDate(now.getDate() - 8);
        this.laborDate.fromTime = now.toJSON().slice(0, 10);
      }

      if (!this.laborDate.toTime) {
        const toTime = new Date();
        toTime.setDate(toTime.getDate() - 1);
        this.laborDate.toTime = toTime.toJSON().slice(0, 10);
      }

      arrPromises.push(this.fetchLaborCost());

      if (this.checkFullTimeCost()) {
        if (!this.fullTimeStaffDate.fromTime) {
          const now = new Date();
          now.setDate(now.getDate() - 8);
          this.fullTimeStaffDate.fromTime = now.toJSON().slice(0, 10);
        }

        if (!this.fullTimeStaffDate.toTime) {
          const toTime = new Date();
          toTime.setDate(toTime.getDate() - 1);
          this.fullTimeStaffDate.toTime = toTime.toJSON().slice(0, 10);
        }

        arrPromises.push(this.fetchFullTimeCost());
      }

      if (window.requestIdleCallback) {
        requestIdleCallback(this.getCashExpenseReport);
      } else {
        this.getCashExpenseReport();
      }
    }

    Promise.allSettled(arrPromises).then(() => {
      if (!this.deferredShouldShown && this.$refs.defer1) {
        this.observer.observe(this.$refs.defer1);
      }
    });

    if (this.roles.find(role => ['Admin', 'Warehouse Manager', 'Procurement Manager'].includes(role.name))) {
      Promise.allSettled(arrPromises).then(() => {
        if (!this.poQuery.fromTime) {
          const now = new Date();
          now.setDate(now.getDate() - 8);
          this.poQuery.fromTime = now.toJSON().slice(0, 10);
        }

        if (!this.poQuery.toTime) {
          const toTime = new Date();
          toTime.setDate(toTime.getDate() - 1);
          this.poQuery.toTime = toTime.toJSON().slice(0, 10);
        } // Top 10 Suppliers


        this.fetchSuppliersPO();

        if (!this.supplierQuery.fromTime) {
          const now = new Date();
          now.setDate(now.getDate() - 8);
          this.supplierQuery.fromTime = now.toJSON().slice(0, 10);
        }

        if (!this.supplierQuery.toTime) {
          const toTime = new Date();
          toTime.setDate(toTime.getDate() - 1);
          this.supplierQuery.toTime = toTime.toJSON().slice(0, 10);
        } // @TODO break down the array into 4 groups and keep fetch the child
        // then daily refetch
        // Store Purchase Report


        this.fetchSuppliersStore();
        this.fetchWarehouseSummary();

        if (!this.deferred2ShouldShown && this.$refs.defer2) {
          this.observer.observe(this.$refs.defer2);
        }
      }); // @TODO fetch estimate
      // Warehouse
    } else {
      Promise.allSettled(arrPromises).then(() => {
        if (!this.deferred2ShouldShown && this.$refs.defer2) {
          this.observer.observe(this.$refs.defer2);
        }
      });
    }
  },

  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    // console.log('mounted')
    this.observer = new IntersectionObserver(this.loadDeferred, {
      threshold: 0.1
    });
    /* if (!this.deferred2ShouldShown) {
      this.observer.observe(this.$refs.defer1)
    } */
  },

  methods: {
    async loadDeferred([{
      isIntersecting,
      target
    }]) {
      if (isIntersecting) {
        await this.$nextTick();

        if (!this.deferredShouldShown && target === this.$refs.defer1) {
          this.deferredShouldShown = true;
        } else if (!this.deferred2ShouldShown) {
          this.deferred2ShouldShown = true;
          this.observer.disconnect();
        } // this.observer.disconnect()

      }
    },

    fetchStoreStaffDuty() {
      // const currentDate = ''
      const param = {
        // date: currentDate,
        fromTime: this.staffDate.fromTime,
        toTime: this.staffDate.toTime
      };
      this.storeStaffDutyLoading = true;
      return (0, _report.getStoreStaffDuty)(param).then(response => {
        /* var res = response.data
        var data = []
        for (let i = 0; i < res.length; i++) {
          if (parseFloat(res[i].activeCost) > 0) {
            data.push(res[i])
          }
        } */

        /* data.map((store, i) => {
          const duties = []
          store.duties.reduce(function(res, value) {
            if (!res[value.userId]) {
              res[value.userId] = { ...value, userId: value.userId, scheduledDuration: 0, scheduledCost: 0 }
              duties.push(res[value.userId])
            }
            res[value.userId].scheduledDuration += value.scheduledDuration
            res[value.userId].scheduledCost += value.scheduledCost
            // missed, pending, active, completed
            if (value.status === 'missed') {
              res[value.userId].status = 'missed'
            } else if (res[value.userId].status === 'active' && ['missed', 'pending'].indexOf(value.status) !== -1) {
              res[value.userId].status = value.status
            } else if (res[value.userId].status === 'completed') {
              res[value.userId].status = value.status
            }
            return res
          }, {})
          store.duties = duties
        })*/
        if (response && response.data) {
          this.storeStaffDutyData = response.data;
        }

        return this.storeStaffDutyData;
      }).finally(() => {
        this.storeStaffDutyLoading = false;
      });
    },

    fetchLaborCost() {
      // const currentDate = ''
      const param = {
        // date: currentDate,
        fromTime: this.laborDate.fromTime,
        toTime: this.laborDate.toTime
      };
      this.laborCostLoading = true;
      return (0, _report.getLaborCost)(param).then(response => {
        if (response && response.data) {
          this.laborCostData = response.data;
        }

        return this.laborCostData;
      }).finally(() => {
        this.laborCostLoading = false;
      });
    },

    fetchFullTimeCost() {
      // const currentDate = ''
      const param = {
        // date: currentDate,
        fromTime: this.fullTimeStaffDate.fromTime,
        toTime: this.fullTimeStaffDate.toTime
      };
      this.fullTimeCostLoading = true;
      return (0, _report.getfullTimeCost)(param).then(response => {
        if (response && response.data) {
          this.fullTimeCostData = response.data;
        }

        return this.fullTimeCostData;
      }).finally(() => {
        this.fullTimeCostLoading = false;
      });
    },

    async handleExpand(row, expandedRows) {
      if (expandedRows.length > 0 && (!row.duties || !row.duties.length) && expandedRows.indexOf(row) !== -1) {
        (0, _report.getStoreCost)({
          storeId: row.uuid,
          fromTime: this.staffDate.fromTime,
          toTime: this.staffDate.toTime
        }).then(response => {
          if (response && response.data) {
            this.storeStaffDutyData[this.storeStaffDutyData.indexOf(row)].duties = response.data[0].duties;
          }
        });
      }
    },

    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();

      if (month.length < 2) {
        month = '0' + month;
      }

      if (day.length < 2) {
        day = '0' + day;
      }

      return [year, month, day].join('-');
    },

    fetchSuppliersPO() {
      let controller = null;

      if (this.poQuery.fromTime || this.poQuery.toTime) {
        controller = new AbortController();
        this.cleanOnSuppliersPOChange.push(controller);
        this.clearOnRouteChange.push(controller);
      }

      return (0, _report.getSuppliersPO)({ ...this.poQuery,
        orderBy: this.supplierSortBy
      }, {
        signal: controller ? controller.signal : null
      }).then(response => {
        if (response && response.data) {
          this.suppliersPO = response.data;
          this.totalSupplierPO = response.meta.total;
        }

        return this.suppliersPO;
      });
    },

    fetchSuppliersStore() {
      this.supplierLoading = true;
      return (0, _report.getSupplierPOStore)({ ...this.supplierQuery,
        orderBy: this.supplierSortBy
      }).then(response => {
        // console.log(response)
        if (response && response.data) {
          this.suppliersStore = response.data;
        }
        /*
        this.totalSupplierStore = response.meta.total
        */


        return this.suppliersStore;
      }).finally(() => {
        this.supplierLoading = false;
      });
    },

    fetchSuppliersStoreByPO() {
      this.supplierLoading = true;
      (0, _report.getSupplierByPOStore)({ ...this.supplierQuery,
        orderBy: this.supplierSortBy
      }).then(response => {
        this.suppliersStoreEstimate = response.data;
      }).finally(() => {
        this.supplierLoading = false;
      });
    },

    fetchWarehouseSummary() {
      (0, _report.getWarehouseReport)({ ...this.warehouseQuery,
        orderBy: this.warehouseSortBy
      }).then(response => {
        this.warehouses = response.data;
        this.totalWarehouse = response.meta.total;
      });
    },

    getCashExpenseReport() {
      if (this.cashExpenseParam.date_range === null) {
        var start = new Date();
        start.setDate(start.getDate() - 7);
        var dd = String(start.getDate()).padStart(2, '0');
        var mm = String(start.getMonth() + 1).padStart(2, '0'); // January is 0!

        var yyyy = start.getFullYear();
        var format1 = yyyy + '-' + mm + '-' + dd;
        var next = new Date();
        dd = String(next.getDate()).padStart(2, '0');
        mm = String(next.getMonth() + 1).padStart(2, '0'); // January is 0!

        yyyy = next.getFullYear();
        var format2 = yyyy + '-' + mm + '-' + dd;
        this.cashExpenseParam.date_range = [format1, format2];
      } // this.cashExpenseLoading = true


      (0, _cashExpense.getReport)(this.cashExpenseParam).then(response => {
        this.cashExpenses = response;
      }).finally(() => {// this.cashExpenseLoading = false
      });
    },

    dateFormat(row, column) {
      var date = row[column.property];

      if (!date) {
        return '';
      }

      return (0, _moment.default)(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
    },

    checkFullTimeCost() {
      if ((this.user.name === 'Waiman' || this.user.name === 'Wilson Lau' || this.user.name === 'Akbar') && this.roles.find(role => ['Admin'].includes(role.name))) {
        return true;
      } else {
        return false;
      }
    },

    supplierSummary() {
      // console.log('SupplierSummary')
      var param = {
        fromTime: this.poQuery.fromTime,
        toTime: this.poQuery.toTime
      };
      (0, _report.downloadSupplierSummary)(param).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `summary.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(err => {
        console.error(err);
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      });
    },

    /* oneSupplierSummary(id) {
      var param = {
        id: id,
        fromTime: this.supplierWeek[0],
        toTime: this.supplierWeek[1]
      }
       exportSupplierPOSummary(param).then(response => {
        const fileURL = window.URL.createObjectURL(response)
        const fileLink = document.createElement('a')
         fileLink.href = fileURL
        fileLink.setAttribute('download', `summary.pdf`)
        document.body.appendChild(fileLink)
         fileLink.click()
      })
    },*/
    supplierStoreSummary(store) {
      if (this.suppliersStoreMode === 'real') {
        (0, _report.downloadSupplierStoreSummary)({
          fromTime: this.supplierQuery.fromTime,
          toTime: this.supplierQuery.toTime
        }).then(response => {
          const fileURL = window.URL.createObjectURL(response);
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `store-purchase-report.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(err => {
          console.error(err);
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      } else {
        (0, _report.downloadSupplierStoreByPOSummary)({
          fromTime: this.supplierQuery.fromTime,
          toTime: this.supplierQuery.toTime,
          store: store
        }).then(response => {
          const fileURL = window.URL.createObjectURL(response);
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `store-purchase-report.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(err => {
          console.error(err);
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }
    },

    warehouseItemSummary(warehouse) {
      (0, _report.downloadWarehouseItemSummary)({
        warehouse: warehouse
      }).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `warehouse-item-report.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(err => {
        console.error(err);
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      });
    },

    sortSupplierPOChanged(sortProps) {
      this.supplierSortBy = {
        [sortProps.prop]: sortProps.order
      };
      this.beforeChangeSupplierPO();
      this.fetchSuppliersPO();
    },

    sortSupplierStoreChanged(sortProps) {
      this.supplierSortBy = {
        [sortProps.prop]: sortProps.order
      };
      this.fetchSuppliersStore();
    },

    warehouseSummary(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = _i18n.default.t('Order.Total') + ':';
        } else if (index === 3) {
          const values = data.map(item => Number(item[column.property]));

          if (!values.every(value => isNaN(value))) {
            let total = 0;
            total = values.reduce((prev, curr) => {
              const value = Number(curr);

              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = Math.round(total * 100) / 100;
          } else {
            sums[index] = '0';
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },

    laborCostSummary(param) {
      const {
        data
      } = param;
      const sums = [];
      var totalLaborCost = 0;
      var totalTgtSales = 0;
      var totalSale = 0;
      var totalCOGS = 0;
      data.forEach((column, index) => {
        totalLaborCost = totalLaborCost + column.laborCost;
        totalTgtSales = totalTgtSales + column.targetSales;
        totalSale = totalSale + column.sales;
      });
      sums[0] = _i18n.default.t('Order.Total') + ':';
      sums[1] = '$' + parseFloat(parseFloat(totalLaborCost).toFixed(2));
      sums[2] = '$' + parseFloat(parseFloat(totalTgtSales).toFixed(2));
      sums[3] = '$' + parseFloat(parseFloat(totalSale).toFixed(2));

      if (totalSale > 0) {
        totalCOGS = parseFloat(parseFloat(totalLaborCost / totalSale * 100).toFixed(2));
      }

      sums[4] = totalCOGS + '%';
      return sums;
    },

    pluralize(unit) {
      if (['kg', 'gr', 'l', 'ml', 'mg'].indexOf(unit) === -1) {
        return (0, _pluralize.default)(unit);
      } else {
        return unit;
      }
    },

    beforeChangeSupplierPO() {
      if (this.cleanOnSuppliersPOChange.length) {
        for (const instance of this.cleanOnSuppliersPOChange) {
          if (instance instanceof AbortController) {
            instance.abort();
          } else {
            clearTimeout(instance);
          }
        }

        this.cleanOnSuppliersPOChange = [];
      }
    },

    filterCategory(category) {
      // this.cashExpenseParam.category = category;
      // this.getCashExpenseReport();
      this.$router.push(`/cash-expense/` + category + `/` + this.cashExpenseParam.date_range);
    },

    handleSupplierRowExpand(row, expandedRows, storeId) {
      if (expandedRows.length > 0 && expandedRows.indexOf(row) !== -1) {
        let supplierId = null;
        let idx = -1;
        let ids = -1; // console.log('populate storeId ' + storeId + ' row uuid ' + row.uuid)

        for (let p = 0; p < this.suppliersStore.length; p++) {
          if (this.suppliersStore[p].uuid === storeId) {
            ids = p;

            for (let i = 0; i < this.suppliersStore[p].suppliers.length; i++) {
              if (this.suppliersStore[p].suppliers[i].uuid === row.uuid) {
                if (this.suppliersStore[p].suppliers[i].summary && this.suppliersStore[p].suppliers[i].summary.length > 1) {
                  // console.log('Row Supplier ' + i + ' uuid ' + row.uuid + ' name ' + row.name + ' store row ' + p + ' has summaries ' + this.suppliersStore[p].suppliers[i].summary.length)
                  return;
                }

                this.supplierLoading = true;
                idx = i;
                supplierId = row.uuid;
                break;
              }
            }

            break;
          }
        }

        if (supplierId) {
          // console.log({ supplierId })
          (0, _report.getSupplierPOStore)({ ...this.supplierQuery,
            storeId,
            supplierId
          }).then(suppliersStore => {
            // console.log(suppliersStore.data[0].suppliers, suppliersStore[0])
            this.suppliersStore[ids].suppliers[idx].summary = this.suppliersStore[ids].suppliers[idx].summary.concat(suppliersStore.data[0].suppliers[0].summary);
            /*
                      this.totalSupplierStore = response.meta.total
                      */
          }).finally(() => {
            this.supplierLoading = false;
          });
        }
      }
    },

    handleSupplierRowExpandPO(row, expandedRows, storeId) {
      if (expandedRows.length > 0 && expandedRows.indexOf(row) !== -1) {
        let supplierId = null;
        let idx = -1;
        let ids = -1; // console.log('populate storeId ' + storeId + ' row uuid ' + row.uuid)

        for (let p = 0; p < this.suppliersStoreEstimate.length; p++) {
          if (this.suppliersStoreEstimate[p].uuid === storeId) {
            ids = p;

            for (let i = 0; i < this.suppliersStoreEstimate[p].suppliers.length; i++) {
              if (this.suppliersStoreEstimate[p].suppliers[i].uuid === row.uuid) {
                if (this.suppliersStoreEstimate[p].suppliers[i].summary && this.suppliersStoreEstimate[p].suppliers[i].summary.length > 1) {
                  // console.log('Row Supplier ' + i + ' uuid ' + row.uuid + ' name ' + row.name + ' store row ' + p + ' has summaries ' + this.suppliersStore[p].suppliers[i].summary.length)
                  return;
                }

                this.supplierLoading = true;
                idx = i;
                supplierId = row.uuid;
                break;
              }
            }

            break;
          }
        }

        if (supplierId) {
          // console.log({ supplierId })
          (0, _report.getSupplierByPOStore)({ ...this.supplierQuery,
            storeId,
            supplierId
          }).then(suppliersStore => {
            // console.log(suppliersStore.data[0].suppliers, suppliersStore[0])
            if (suppliersStore && suppliersStore.data) {
              this.suppliersStoreEstimate[ids].suppliers[idx].summary = this.suppliersStoreEstimate[ids].suppliers[idx].summary.concat(suppliersStore.data[0].suppliers[0].summary);
            }
            /*
                      this.totalSupplierStore = response.meta.total
                      */

          }).finally(() => {
            this.supplierLoading = false;
          });
        }
      }
    },

    handleStoreRowExpand(row, expandedRows) {
      if (expandedRows.length > 0 && expandedRows.indexOf(row) !== -1) {
        let storeId = null;
        let idx = -1;

        for (let p = 0; p < this.suppliersStore.length; p++) {
          if (this.suppliersStore[p].uuid === row.uuid) {
            if (this.suppliersStore[p].suppliers && this.suppliersStore[p].suppliers.length > 1) {
              return;
            }

            this.supplierLoading = true;
            idx = p;
            storeId = row.uuid;
            break;
          }
        }

        if (storeId) {
          (0, _report.getSupplierPOStore)({ ...this.supplierQuery,
            storeId
          }).then(suppliersStore => {
            // console.log(suppliersStore.data[0].suppliers, suppliersStore[0])
            if (suppliersStore && suppliersStore.data) {
              this.suppliersStore[idx].suppliers = this.suppliersStore[idx].suppliers.concat(suppliersStore.data[0].suppliers);
            }
            /*
                      this.totalSupplierStore = response.meta.total
                      */

          }).finally(() => {
            this.supplierLoading = false;
          });
        }
      }
    },

    handleStoreRowExpandPO(row, expandedRows) {
      if (expandedRows.length > 0 && expandedRows.indexOf(row) !== -1) {
        let storeId = null;
        let idx = -1;

        for (let p = 0; p < this.suppliersStoreEstimate.length; p++) {
          if (this.suppliersStoreEstimate[p].uuid === row.uuid) {
            if (this.suppliersStoreEstimate[p].suppliers && this.suppliersStoreEstimate[p].suppliers.length > 1) {
              return;
            }

            this.supplierLoading = true;
            idx = p;
            storeId = row.uuid;
            break;
          }
        }

        if (storeId) {
          (0, _report.getSupplierByPOStore)({ ...this.supplierQuery,
            storeId
          }).then(suppliersStore => {
            // console.log(suppliersStore.data[0].suppliers, suppliersStore[0])
            this.suppliersStoreEstimate[idx].suppliers = this.suppliersStoreEstimate[idx].suppliers.concat(suppliersStore.data[0].suppliers);
            /* this.totalSupplierStore = response.meta.total */
          }).finally(() => {
            this.supplierLoading = false;
          });
        }
      }
    },

    handleRowExpand(row, expandedRows) {
      if (expandedRows.length > 0 && expandedRows.indexOf(row) !== -1) {
        var idx = -1;

        for (let p = 0; p < this.suppliersPO.length; p++) {
          if (this.suppliersPO[p].supplier_id === row.supplier_id) {
            if (this.suppliersPO[p].summary.length > 1) {
              return;
            }

            idx = p;
            break;
          }
        }

        if (idx > -1) {
          return (0, _report.getSupplierPOSummary)({
            id: row.supplier_id,
            fromTime: this.poQuery.fromTime,
            toTime: this.poQuery.toTime
          }).then(summary => {
            var supplier = this.suppliersPO[idx];
            supplier.summary = [];

            for (let i = 0; i < summary.length; i++) {
              supplier.summary.push({
                sku: summary[i].sku,
                qty: summary[i].qty,
                unit: summary[i].unit,
                amount: summary[i].amount
              });
            }
          });
        }
      }
    },

    translateCategory(key) {
      var category_name = '';

      for (let i = 0; i < this.cashExpenseCategories.length; i++) {
        const category = this.cashExpenseCategories[i];

        if (category.id === key) {
          category_name = category.name;
          break;
        }
      }

      return category_name;
    }

  }
};
exports.default = _default;