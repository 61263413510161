var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.addTitle, visible: _vm.visible },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm.newDuty
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.dutyForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Shift", prop: "shifts" } },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.userShifts,
                      label: "name",
                      reduce: function (shift) {
                        return shift.uuid
                      },
                      clearable: false,
                    },
                    on: {
                      input: _vm.onShiftSelected,
                      open: _vm.onOpen,
                      close: _vm.onClose,
                      search: _vm.fetchShift,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "list-footer",
                          fn: function () {
                            return [
                              _c(
                                "li",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.hasNextPage,
                                      expression: "hasNextPage",
                                    },
                                  ],
                                  ref: "load",
                                  staticClass: "loader",
                                },
                                [
                                  _vm._v(
                                    "\n            Loading more options...\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      925453255
                    ),
                    model: {
                      value: _vm.dutyForm.shiftId,
                      callback: function ($$v) {
                        _vm.$set(_vm.dutyForm, "shiftId", $$v)
                      },
                      expression: "dutyForm.shiftId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: _vm.$t("Order.Name") } }, [
                _vm._v("\n      " + _vm._s(_vm.newDuty.user.name) + "\n    "),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Role", prop: "roleId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "Select",
                        disabled: _vm.userRoles.length === 1,
                      },
                      on: { change: _vm.onRoleSelected },
                      model: {
                        value: _vm.dutyForm.roleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dutyForm, "roleId", $$v)
                        },
                        expression: "dutyForm.roleId",
                      },
                    },
                    _vm._l(_vm.userRoles, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "From", prop: "fromTime" } },
                [
                  _c("el-time-select", {
                    attrs: {
                      "picker-options": {
                        start: _vm.minTime,
                        step: _vm.step,
                        end: _vm.maxTime,
                      },
                      disabled:
                        !!_vm.newDuty.clockInAttendanceRecord &&
                        !_vm.user.roles.find(function (role) {
                          return ["Operation Manager", "Admin"].includes(
                            role.name
                          )
                        }),
                      placeholder: "Start time",
                      filterable: "",
                    },
                    on: { change: _vm.onStartTimeSelected },
                    model: {
                      value: _vm.dutyForm.fromTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.dutyForm, "fromTime", $$v)
                      },
                      expression: "dutyForm.fromTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "To", prop: "toTime" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.toggleNextDate },
                      model: {
                        value: _vm.dutyForm.nextDay,
                        callback: function ($$v) {
                          _vm.$set(_vm.dutyForm, "nextDay", $$v)
                        },
                        expression: "dutyForm.nextDay",
                      },
                    },
                    [_vm._v("\n        Next Day\n      ")]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("el-time-select", {
                    attrs: {
                      "picker-options": {
                        start: _vm.minTime,
                        step: _vm.step,
                        end: _vm.dutyForm.nextDay ? "02:00" : _vm.maxTime,
                        minTime: _vm.dutyForm.nextDay
                          ? null
                          : _vm.dutyForm.fromTime,
                      },
                      placeholder: "End time",
                    },
                    on: { change: _vm.onEndTimeSelected },
                    model: {
                      value: _vm.dutyForm.toTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.dutyForm, "toTime", $$v)
                      },
                      expression: "dutyForm.toTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.saveAsShift
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Shift Name", prop: "shiftName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.shift.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.shift, "name", $$v)
                          },
                          expression: "shift.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _vm.showShiftCheckbox
                    ? _c(
                        "el-checkbox",
                        {
                          attrs: { border: "" },
                          model: {
                            value: _vm.saveAsShift,
                            callback: function ($$v) {
                              _vm.saveAsShift = $$v
                            },
                            expression: "saveAsShift",
                          },
                        },
                        [_vm._v("\n        Save as Shift\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isOffline },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }