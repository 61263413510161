var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.user, rules: _vm.rules, "label-width": "auto" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Password", prop: "password" } },
            [
              _c("el-input", {
                attrs: { type: "password", autocomplete: "off" },
                model: {
                  value: _vm.user.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "password", $$v)
                  },
                  expression: "user.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Confirm password",
                prop: "password_confirmation",
              },
            },
            [
              _c("el-input", {
                attrs: { type: "password", autocomplete: "off" },
                model: {
                  value: _vm.user.password_confirmation,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "password_confirmation", $$v)
                  },
                  expression: "user.password_confirmation",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.isOffline,
                    icon: "el-icon-success",
                  },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("Order.Update")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }