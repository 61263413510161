"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    const checkConfirmPassword = (rule, value, callback) => {
      if (this.user.password !== '') {
        if (value === '') {
          callback(new Error('Please confirm the password'));
        } else {
          if (value !== this.user.password) {
            callback(new Error('Password mismatch'));
          }

          callback();
        }
      }
    };

    return {
      loading: false,
      user: {
        password: '',
        password_confirmation: ''
      },
      rules: {
        password: [{
          required: true,
          message: 'Password is required',
          trigger: 'blur'
        }, {
          min: 6,
          message: 'Password can not be less than 6 digits',
          trigger: 'blur'
        }],
        password_confirmation: {
          validator: checkConfirmPassword,
          trigger: 'blur'
        }
      }
    };
  },

  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          this.loading = true;
          (0, _user.changePassword)(this.user).then(() => {
            this.$refs.form.resetFields();
            (0, _elementUi.Message)({
              message: 'Password updated.',
              type: 'success',
              duration: 5 * 1000
            });
          }).catch(err => {
            (0, _elementUi.Message)({
              message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
              type: 'error',
              duration: 5 * 1000
            });
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    }

  }
};
exports.default = _default;